import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import requests from "../../axios/requests";
import { motion } from "framer-motion/dist/framer-motion";

import { Form, Input, message, Modal } from "antd";
import Error404 from "../../components/Error404";
import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import Header from "./Components/Header";
import Banner from "./Components/Banner";
import PreviewSkills from "./Components/PreviewSkills";
import Video from "./Components/Video";
import Speakers from "./Components/Speakers";
import FormatStudy from "./Components/FormatStudy";
import CourseCountains from "./Components/CourseCountains";
import WhoSuits from "./Components/WhoSuits";
import WhatLearn from "./Components/WhatLearn";
import Resume from "./Components/Resume";
import FooterForm from "./Components/FooterForm";
import Button from "../../components/Button/Button";

import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import close_icon from "../../../images/icons/close_modal-course.svg";
import cn from "classnames";

export const ReuseDots = ({activeSlide, setActiveSlide, countDots, color = ""}) => {
    return (
        <div className="block_dots">
            {Array(countDots).fill(0).map((elem, indx) =>
                <div
                    key={indx}
                    onClick={() => setActiveSlide(indx)}
                    style={activeSlide === indx
                        ? {
                            width: 30,
                            height: 4,
                            background: color || "#A1A3B2",
                            borderRadius: 3,
                            marginRight: 8,
                            transition: "all .3s ease",
                            cursor: "pointer"
                        }
                        : {
                            backgroundColor: "transparent",
                            width: 20,
                            height: 4,
                            background: "#343843",
                            borderRadius: 3,
                            marginRight: 8,
                            transition: "all .3s ease",
                            cursor: "pointer"
                        }
                    }/>
            )}
        </div>
    );
};
export const ReuseForm = (props) => {
    let {
        nameFeedback, phone, textFeedback, setPhone, setNameFeedback,
        setTextFeedback, createUserQuestion, isModal = false
    } = props;

    const variable_21 = variableLanguage({keyPage: "landing", keyVariable: "variable_21"});
    const variable_22 = variableLanguage({keyPage: "landing", keyVariable: "variable_22"});
    const variable_23 = variableLanguage({keyPage: "landing", keyVariable: "variable_23"});
    const variable_24 = variableLanguage({keyPage: "landing", keyVariable: "variable_24"});
    const variable_25 = variableLanguage({keyPage: "landing", keyVariable: "variable_25"});
    const variable_26 = variableLanguage({keyPage: "landing", keyVariable: "variable_26"});
    const variable_27 = variableLanguage({keyPage: "landing", keyVariable: "variable_27"});

    if (phone)
        phone = phone.replace(/-/g, "").replace("(", "").replace(")", "").replace(/ /g, "");
    let {TextArea} = Input;
    return (
        <div className="reuse_form">
            {/*{isModal*/}
            {/*    ? <>*/}
            {/*        <div className="h1">{variable_21}</div>*/}
            {/*        <div className="info">{variable_22}</div>*/}
            {/*    </>*/}
            {/*    : <>*/}
            {/*        <div*/}
            {/*            className="h1"*/}
            {/*            style={{color: "white"}}*/}
            {/*        >{variable_21}*/}
            {/*        </div>*/}
            {/*        <div*/}
            {/*            className="info"*/}
            {/*            style={{color: "white"}}*/}
            {/*        >*/}
            {/*            {variable_22}*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*}*/}
            <div className="prev_block">
                <div
                    className="h1"
                    style={{color: "white"}}
                >{variable_21}
                </div>
                <div
                    className="info"
                    style={{color: "white"}}
                >
                    {variable_22}
                </div>
            </div>
            <Form
                className="feedbackForm"
                layout="vertical"
                size="large"
                onFinish={createUserQuestion}
                style={{position: "relative", zIndex: 3}}
            >
                <Input
                    className={"input_classic"}
                    placeholder={variable_23}
                    value={nameFeedback || ""}
                    onChange={(e) => setNameFeedback(e.target.value)}
                />
                <Input
                    className={"input_classic"}
                    placeholder={variable_24}
                    value={phone || ""}
                    onChange={(e) => {
                        let value = e.target.value || "";
                        value = value.replace(/[^0-9+]/g, "");
                        if (value.length > 13)
                            return
                        setPhone(value)
                    }}
                />
                <TextArea
                    className={"input_classic text_area"}
                    style={{resize: "none"}}
                    placeholder={variable_25}
                    value={textFeedback || ""}
                    onChange={(e) => setTextFeedback(e.target.value)}
                />
                <Button
                    text={variable_27}
                    click={() => {
                    }}
                    margin={"15px 0 0 0"}
                    padding={"12px"}
                    htmlType="submit"
                />
                <div className="deal" style={{color: "white"}}>{variable_26}</div>
                {/*<Form.Item>*/}
                {/*    <Input*/}
                {/*        className={"input_classic"}*/}
                {/*        placeholder={variable_23}*/}
                {/*        value={nameFeedback || ""}*/}
                {/*        onChange={(e) => setNameFeedback(e.target.value)}*/}
                {/*    />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item>*/}
                {/*    <Input*/}
                {/*        className={"input_classic"}*/}
                {/*        placeholder={variable_24}*/}
                {/*        value={phone || ""}*/}
                {/*        onChange={(e) => {*/}
                {/*            let value = e.target.value || "";*/}
                {/*            value = value.replace(/[^0-9+]/g, "");*/}
                {/*            if (value.length > 13)*/}
                {/*                return*/}
                {/*            setPhone(value)*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item name="text" validateTrigger={"onBlur"}>*/}
                {/*    <TextArea*/}
                {/*        className={"input_classic text_area"}*/}
                {/*        style={{resize: "none"}}*/}
                {/*        placeholder={variable_25}*/}
                {/*        value={textFeedback || ""}*/}
                {/*        onChange={(e) => setTextFeedback(e.target.value)}*/}
                {/*    />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item>*/}
                {/*    {isModal*/}
                {/*        ? <div className="deal">{variable_26}</div>*/}
                {/*        : <div className="deal" style={{color: "white"}}>{variable_26}</div>*/}
                {/*    }*/}
                {/*</Form.Item>*/}
                {/*<Form.Item>*/}
                {/*    <Button*/}
                {/*        text={variable_27}*/}
                {/*        click={() => {*/}
                {/*        }}*/}
                {/*        margin={"15px 0 0 0"}*/}
                {/*        padding={"12px"}*/}
                {/*        htmlType="submit"*/}
                {/*    />*/}
                {/*</Form.Item>*/}
            </Form>
        </div>
    );
};
const Landing = () => {
    const params = useParams();

    const variable_1 = variableLanguage({keyPage: "landing", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "landing", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "landing", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "landing", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "landing", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "landing", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "landing", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "landing", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "landing", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "landing", keyVariable: "variable_10"});
    const variable_29 = variableLanguage({keyPage: "landing", keyVariable: "variable_29"});
    const variable_30 = variableLanguage({keyPage: "landing", keyVariable: "variable_30"});
    const variable_31 = variableLanguage({keyPage: "landing", keyVariable: "variable_31"});
    const variable_32 = variableLanguage({keyPage: "landing", keyVariable: "variable_32"});
    const variable_33 = variableLanguage({keyPage: "landing", keyVariable: "variable_33"});
    const variable_41 = variableLanguage({keyPage: "landing", keyVariable: "variable_41"});

    const quest_id = params?.id;
    const {user, collapse} = useSelector(state => state.Reducer);
    const [data, setData] = useState(null);
    const [trigger, setTrigger] = useState(false);
    const [activeSlideFeedback, setActiveSlideFeedback] = useState(0);
    const [activeSlideFeedbackMobile, setActiveSlideFeedbackMobile] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [completedCourses, setCompletedCourses] = useState([]);
    const [skillTags, setSkillTags] = useState([]);
    const [profile, setProfile] = useState({});
    const [currentPage] = useState(1);
    const [page_size] = useState(20);

    const [nameFeedbackModal, setNameFeedbackModal] = useState("");
    const [nameFeedbackFooter, setNameFeedbackFooter] = useState("");
    const [phoneFooter, setPhoneFooter] = useState("");
    const [phoneModal, setPhoneModal] = useState("");
    const [textFeedbackFooter, setTextFeedbackFooter] = useState("");
    const [textFeedbackModal, setTextFeedbackModal] = useState("");
    const [arrNavBar, setArrNavbar] = useState([
        {name: "Каталог курсов", valid: true},
        {name: "Спикеры", valid: false},
        {name: "О курсе", valid: false},
        {name: "Кому подойдет", valid: false},
        {name: "Что получите", valid: false},
    ]);

    const [flagAuth, setFlagAuth] = useState(false);
    const [flagDropDownPhone, setFlagDropDownPhone] = useState(false);
    const [flagModalFeedback, setFlagModalFeedback] = useState(false);
    const [flagSuccessSendQuestion, setFlagSuccessSendQuestion] = useState(true);
    const [flagShow404, setFlagShow404] = useState(null);

    let scrollIntoView = (ref) => {
        if (typeof ref?.current !== "undefined")
            ref.current.scrollIntoView({block: "center", behavior: "smooth"});
    };
    let handleShowSkills = (tag = "") => {
        if (typeof skillRef?.current !== "undefined")
            skillRef.current.scrollIntoView({block: "center", behavior: "smooth"});
        else
            window.open(`https://www.google.com/search?q=${tag}`, "blank");
    };
    let handleChangeNameFeedback = (e, isFooter = false) => {
        const notNumber = e.replace(/[^a-z | _ | а-я]/gi, "");
        if (isFooter)
            setNameFeedbackFooter(notNumber);
        else
            setNameFeedbackModal(notNumber);
    };
    let createApiQuestion = (obj) => {
        requests.quests.post_user_question(obj)
            .then((res) => {
                message.success(variable_29);
                setFlagSuccessSendQuestion(false);
            })
            .catch((err) => console.error(err));
    };
    let createUserQuestionFooter = () => {
        if (!flagSuccessSendQuestion) return message.error(variable_30);
        if (!nameFeedbackFooter) return message.error(variable_31);
        if (!phoneFooter) return message.error(variable_32);
        if (!textFeedbackFooter) return message.error(variable_33);

        let obj = {
            ses: data.ses,
            name: nameFeedbackFooter,
            phone: phoneFooter,
            text: textFeedbackFooter
        };
        createApiQuestion(obj);
    };
    let createUserQuestionModal = () => {
        if (!flagSuccessSendQuestion) return message.error(variable_30);
        if (!nameFeedbackModal) return message.error(variable_31);
        if (!phoneModal) return message.error(variable_32);
        if (!textFeedbackModal) return message.error(variable_33);

        let obj = {
            ses: data.ses,
            name: nameFeedbackModal,
            phone: phoneModal,
            text: textFeedbackModal
        };
        createApiQuestion(obj);
        setFlagModalFeedback(false);
    };
    let getApiCourse = () => {
        requests.quests.get_course_id("/" + quest_id)
            .then((res) => {
                setData(res.data);
                let addCompletedCourse = {
                    name: res.data.name,
                    description: res.data.description,
                    passed_date: new Date().getFullYear()
                }
                const newArr = JSON.parse(JSON.stringify(arrNavBar));

                setCompletedCourses(prev => [...prev, addCompletedCourse]);
                if (res.data?.quest_speakers && res.data.quest_speakers.length > 0)
                    newArr[1].valid = true;
                if (res.data?.quest_recommendations && res.data.quest_recommendations.length > 0)
                    newArr[3].valid = true;
                if (res.data?.quest_skills && res.data.quest_skills.length > 0)
                    newArr[4].valid = true;
                if (res.data?.speciality_tag?.length > 0) {
                    setSkillTags((prev) => {
                        let skills = [...skillTags];
                        res.data.speciality_tag.map(tag => {
                            skills = [...skills, tag.name]
                        })
                        setSkillTags(skills)
                    })
                }

                newArr[0].name = variable_1;
                newArr[1].name = variable_2;
                newArr[2].name = variable_3;
                newArr[3].name = variable_4;
                newArr[4].name = variable_5;
                setArrNavbar(newArr)
                setFlagShow404(false);
            })
            .catch((err) => {
                if (err.message.includes("Request failed with status code 404"))
                    setFlagShow404(true);
            });
    }
    let getApiMyCourse = () => {
        requests.quests.get_quest_quest([], currentPage, page_size, "", "", "finished", false, "")
            .then(res => setCompletedCourses(res.data.results))
            .catch((error) => console.log(" get_quest_quest", error))
    }

    const history = useNavigate();
    const skillRef = useRef();
    const speakersRef = useRef();
    const formatStudyRef = useRef();
    const whoSuitsRef = useRef();
    const speakersRefMobile = useRef();
    const formatStudyRefMobile = useRef();
    const whoSuitsRefMobile = useRef();
    const carouselRefFeedback = useRef(null);
    const carouselRefFeedbackMobile = useRef(null);
    const flagShowFormat = data !== null && Object.keys(data).length !== 0 &&
        (data.progress.count_video > 0 || data.progress.count_theories > 0 || data.progress.count_task > 0 || data.progress.count_code_task > 0 ||
            data.progress.count_task > 0 || data.progress.count_quiz > 0 || data.progress.count_article > 0);
    const skillList = [
        {
            id: 10,
            active: false,
            title: variable_3
        },
        {
            id: 4,
            active: true,
            title: variable_4
        },
        {
            id: 2,
            active: true,
            title: variable_5
        },
        {
            id: 5,
            active: true,
            title: variable_6

        },
        {
            id: 3,
            active: true,
            title: variable_7
        },
        {
            id: 7,
            active: true,
            title: variable_8
        },
        {
            id: 6,
            active: true,
            title: variable_9
        },
        {
            id: 1,
            active: true,
            title: variable_10
        }
    ];

    useEffect(async () => {
        if (localStorage.getItem("token")) {
            getApiMyCourse();

            const userTags = (await requests.quests.get_user_tags()).data;
            skillList.forEach((skill) => {
                if (userTags[`${skill.id - 1}`]) {
                    userTags[`${skill.id - 1}`].tags.map((t) => {
                        setSkillTags((prev) => [...prev, t.name])
                    })
                }
            });
            setFlagAuth(true)
        }
    }, []);
    useEffect(() => {
        if (typeof user?.id !== 'undefined' && user.id)
            setProfile(user)
    }, [user]);
    useEffect(() => {
        if (carouselRefFeedbackMobile.current?.slickGoTo)
            carouselRefFeedbackMobile.current.slickGoTo(activeSlideFeedbackMobile);
    }, [activeSlideFeedbackMobile]);
    useEffect(() => {
        if (carouselRefFeedback.current?.slickGoTo)
            carouselRefFeedback.current.slickGoTo(activeSlideFeedback);
    }, [activeSlideFeedback]);
    useEffect(() => {
        if (data !== null) {
            requests.quests.get_quest_review(data.id)
                .then((res) => {
                    setReviews(res.data);
                })
                .catch(err => console.error(err));
        }
    }, [data]);
    useEffect(() => {
        if (variable_1)
            getApiCourse();
    }, [variable_1, trigger])
    return (
        <>
            {flagAuth &&
                <div
                    className={cn("btn_prev-landing", {
                        margin_left: collapse
                    })}
                    onClick={() => history("/student/course-list")}
                >
                    <Arrow/>
                    <span>
                        {variable_41}
                    </span>
                </div>
            }
            <motion.div
                className={flagAuth ? "parent_landing auth" : "parent_landing"}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <Header
                    arrNavBar={arrNavBar}
                    flagAuth={flagAuth}
                    handleShowSkills={handleShowSkills}
                    scrollIntoView={scrollIntoView}
                    refs={{
                        speakersRef, formatStudyRef, whoSuitsRef,
                        speakersRefMobile, formatStudyRefMobile, whoSuitsRefMobile
                    }}
                />
                {flagShow404 !== null &&
                    <>
                        {flagShow404
                            ? <Error404/>
                            : <>
                                <Banner
                                    data={data}
                                    setTrigger={ () => setTrigger(!trigger) }
                                    setFlagModalFeedback={setFlagModalFeedback}
                                    flagAuth={flagAuth}
                                    profile={profile}
                                    quest_id={quest_id}
                                />
                                {/*<PreviewSkills*/}
                                {/*    data={data}*/}
                                {/*    handleShowSkills={handleShowSkills}*/}
                                {/*/>*/}
                                {/*<Video data={data}/>*/}
                                <Speakers
                                    data={data}
                                    speakersRef={speakersRef}
                                    speakersRefMobile={speakersRefMobile}
                                />
                                <FormatStudy
                                    data={data}
                                    flagShowFormat={flagShowFormat}
                                    formatStudyRef={formatStudyRef}
                                    formatStudyRefMobile={formatStudyRefMobile}
                                />
                                <CourseCountains
                                    data={data}
                                />
                                <WhoSuits
                                    data={data}
                                    whoSuitsRef={whoSuitsRef}
                                    whoSuitsRefMobile={whoSuitsRefMobile}
                                />
                                <WhatLearn
                                    data={data}
                                    skillRef={skillRef}
                                />
                                <Resume
                                    flagAuth={flagAuth}
                                    completedCourses={completedCourses}
                                    skillTags={skillTags}
                                />
                                <FooterForm
                                    data={data}
                                    flagAuth={flagAuth}
                                    profile={profile}
                                    quest_id={quest_id}
                                    nameFeedbackFooter={nameFeedbackFooter}
                                    phoneFooter={phoneFooter}
                                    textFeedbackFooter={textFeedbackFooter}
                                    handleChangeNameFeedback={handleChangeNameFeedback}
                                    setPhoneFooter={setPhoneFooter}
                                    setTextFeedbackFooter={setTextFeedbackFooter}
                                    setFlagDropDownPhone={setFlagDropDownPhone}
                                    flagDropDownPhone={flagDropDownPhone}
                                    createUserQuestionFooter={createUserQuestionFooter}
                                />
                            </>
                        }
                    </>
                }
                <>
                    <Modal
                        open={flagModalFeedback}
                        footer={null}
                        className={"modal_feedback"}
                        width={"550px"}
                        closable={false}
                        centered={true}
                        onCancel={() => setFlagModalFeedback(false)}
                        maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                        bodyStyle={{backgroundColor: "white", borderRadius: 40, padding: 40}}
                    >
                        <div
                            className="close"
                            onClick={() => setFlagModalFeedback(false)}
                        >
                            <img src={close_icon} alt=""/>
                        </div>
                        <ReuseForm
                            nameFeedback={nameFeedbackModal}
                            phone={phoneModal}
                            textFeedback={textFeedbackModal}
                            setNameFeedback={(value => handleChangeNameFeedback(value, false))}
                            setPhone={(value) => setPhoneModal(value)}
                            setTextFeedback={(value) => setTextFeedbackModal(value)}
                            setFlagDropDownPhone={setFlagDropDownPhone}
                            flagDropDownPhone={flagDropDownPhone}
                            createUserQuestion={createUserQuestionModal}
                            isModal={true}
                        />
                    </Modal>
                </>
            </motion.div>
        </>
    );
};

export default Landing;