import React, { useRef, useState } from 'react';
import { defaultFuncAndData } from "../../../../utils/defaultData";
import start_ from "../../../../images/icons/start_.svg";
import moment from "moment/moment";
import end_ from "../../../../images/icons/end_.svg";
import academic_hourse from "../../../../images/landing/academic_hourse.svg";
import { useSelector } from "react-redux";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

const CourseCountains = ({data}) => {
    const {language} = useSelector(state => state.Reducer);
    const [showTextCourseContains, setShowTextCourseContains] = useState(false);
    const textDescriptionRef = useRef(null);

    const variable_49 = variableLanguage({keyPage: "landing", keyVariable: "variable_49"});
    const variable_53 = variableLanguage({keyPage: "landing", keyVariable: "variable_53"});
    const variable_54 = variableLanguage({keyPage: "landing", keyVariable: "variable_54"});
    return (
        <>
            {data !== null && Object.keys(data).length !== 0 &&
            (data?.progress?.count_video > 0 || data?.progress?.count_task > 0 || data?.progress?.count_quiz > 0 ||
                data.progress?.count_code_task > 0 || data?.progress?.count_article > 0 || data?.content_description
            )
                ? <div className="course_contains">
                    {/*<div className="h1">{variable_49}</div>*/}
                    <div className="info_row">
                        {(data?.progress.count_video > 0 || data?.progress.count_task > 0 || data.progress?.count_code_task > 0
                            || data?.progress.count_quiz > 0 || data?.progress.count_article > 0)
                            ? <div className="matherial">
                                {data?.progress.count_code_task
                                    ? <div>
                                        <span>
                                            {data?.progress.count_code_task}
                                            {language === "ru"
                                                ? " урока программирования"
                                                : " programming lesson"
                                            }
                                        </span>
                                    </div>
                                    : <></>
                                }
                                {data?.progress.count_video
                                    ? <div>
                                        <span>
                                            {data?.progress.count_video}
                                            {language === "ru"
                                                ? " видео"
                                                : " video"
                                            }
                                        </span>
                                    </div>
                                    : <></>
                                }
                                {data?.progress.count_task
                                    ? <div>
                                        <span>
                                            {data?.progress.count_task}
                                            {language === "ru"
                                                ? " д/з"
                                                : " h/w"
                                            }
                                        </span>
                                    </div>
                                    : <></>
                                }
                                {data?.progress?.count_quiz
                                    ? <div>
                                        <span>
                                            {data.progress.count_quiz}
                                            {language === "ru"
                                                ? <>
                                                    {defaultFuncAndData.declension(data.progress.count_quiz, [" тест", " теста", " тестов"])}
                                                </>
                                                : <>
                                                    {defaultFuncAndData.declension(data.progress.count_quiz, [" test", " tests", " tests"])}
                                                </>
                                            }
                                        </span>
                                    </div>
                                    : <></>
                                }
                                {data?.progress?.count_article
                                    ? <div>
                                        <span>
                                            {data.progress?.count_article}
                                            {language === "ru"
                                                ? <>{defaultFuncAndData.declension(data.progress?.count_article, [" статью", " статьи", " статей"])}</>
                                                : <>{defaultFuncAndData.declension(data.progress?.count_article, [" article", " articles", " articles"])}</>
                                            }
                                        </span>
                                    </div>
                                    : <></>
                                }
                            </div>
                            : <></>
                        }
                        <div className="info">
                            {data?.dateBegin && data?.dateEnd
                                ? <div className="date_row">
                                    <div className="date">
                                        <img src={start_}/>
                                        <span>с {moment(data.dateBegin).format("DD.MM.YY")}</span>
                                        <img src={end_}/>
                                        <span>по {moment(data.dateEnd).format("DD.MM.YY")}</span>
                                    </div>
                                </div>
                                : <></>
                            }

                            {data?.academicHours &&
                                <div className="academic_hours">
                                    <img src={academic_hourse} alt=""/>
                                    <span>
                                        {data.academicHours}
                                        {language === "ru"
                                            ? <> академ {defaultFuncAndData.declension(data.academicHours, [" час", " часа", " часов"])}</>
                                            : <> academ {defaultFuncAndData.declension(data.academicHours, [" hour", " hours", " hours"])}</>
                                        }
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    {data?.content_description &&
                        <>
                            <div className="title_stages">
                                {variable_53}
                            </div>
                            <pre
                                className={showTextCourseContains ? "text_description open" : "text_description"}
                                ref={textDescriptionRef}
                            >
                                {data?.content_description}
                            </pre>
                            {textDescriptionRef?.current?.clientHeight > 199 &&
                                <div
                                    className="show_all"
                                    onClick={() => setShowTextCourseContains(!showTextCourseContains)}
                                >
                                    <span>
                                        {language === "ru"
                                            ? <>{showTextCourseContains ? "Скрыть описание" : "Смотреть описание"}</>
                                            : <>{showTextCourseContains ? "Hide description" : "See description"}</>
                                        }
                                    </span>
                                </div>
                            }
                        </>
                    }
                    {data?.stages?.length === 0 &&
                        <div className="stages">
                            <div className="title_stages">
                                {variable_54}
                            </div>
                            {data.stages.map((elem, indx) =>
                                <div
                                    className="name"
                                    key={indx}
                                >
                                    {elem.name}
                                </div>
                            )}
                        </div>
                    }
                </div>
                : <></>
            }
        </>
    );
};

export default CourseCountains;