import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import requests from "../../axios/requests";

import { message, Modal } from "antd";
import Error404 from "../../components/Error404";
import variableLanguage from "../../../utils/hookGetVariablesLanguage";

import close_icon from "../../../images/icons/close_modal-course.svg";
import { ReuseForm } from "../Landing/Landing";
import Header from "../Landing/Components/Header";
import Banner from "../Landing/Components/Banner";
import PreviewSkills from "../Landing/Components/PreviewSkills";
import Video from "../Landing/Components/Video";
import Speakers from "../Landing/Components/Speakers";
import FormatStudy from "../Landing/Components/FormatStudy";
import CourseCountains from "../Landing/Components/CourseCountains";
import WhoSuits from "../Landing/Components/WhoSuits";
import WhatLearn from "../Landing/Components/WhatLearn";
import Resume from "../Landing/Components/Resume";
import FooterForm from "../Landing/Components/FooterForm";
import { useDispatch } from "react-redux";
import { setIsLandingWeb } from "../../redux/actions/actions";

const LandingWeb = (props) => {
    const variable_1 = variableLanguage({keyPage: "landing", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "landing", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "landing", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "landing", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "landing", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "landing", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "landing", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "landing", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "landing", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "landing", keyVariable: "variable_10"});
    const variable_29 = variableLanguage({keyPage: "landing", keyVariable: "variable_29"});
    const variable_30 = variableLanguage({keyPage: "landing", keyVariable: "variable_30"});
    const variable_31 = variableLanguage({keyPage: "landing", keyVariable: "variable_31"});
    const variable_32 = variableLanguage({keyPage: "landing", keyVariable: "variable_32"});
    const variable_33 = variableLanguage({keyPage: "landing", keyVariable: "variable_33"});

    const params = useParams();
    const dispatch = useDispatch();
    const quest_id = params.id;
    window.localStorage.setItem("token", params.token);

    const [data, setData] = useState(null);
    const [reviews, setReviews] = useState([]);
    const [completedCourses, setCompletedCourses] = useState([]);
    const [skillTags, setSkillTags] = useState([]);
    const [profile, setProfile] = useState({});
    const [currentPage] = useState(1);
    const [page_size] = useState(20);

    const [nameFeedbackModal, setNameFeedbackModal] = useState("");
    const [nameFeedbackFooter, setNameFeedbackFooter] = useState("");
    const [phoneFooter, setPhoneFooter] = useState("");
    const [phoneModal, setPhoneModal] = useState("");
    const [textFeedbackFooter, setTextFeedbackFooter] = useState("");
    const [textFeedbackModal, setTextFeedbackModal] = useState("");
    const [arrNavBar, setArrNavbar] = useState([
        {name: "Каталог курсов", valid: false},
        {name: "Спикеры", valid: false},
        {name: "О курсе", valid: false},
        {name: "Кому подойдет", valid: false},
        {name: "Что получите", valid: false},
    ]);

    const [flagAuth, setFlagAuth] = useState(false);
    const [flagDropDownPhone, setFlagDropDownPhone] = useState(false);
    const [flagModalFeedback, setFlagModalFeedback] = useState(false);
    const [flagSuccessSendQuestion, setFlagSuccessSendQuestion] = useState(true);
    const [flagShow404, setFlagShow404] = useState(null);

    let getProfileUser = () => {
        requests.users.get_profile_new()
            .then((res) => setProfile(res.data))
            .catch(err => console.error(err))
    }
    let scrollIntoView = (ref) => {
        if (typeof ref?.current !== "undefined")
            ref.current.scrollIntoView({block: "center", behavior: "smooth"});
    };
    let handleShowSkills = (tag = "") => {
        if (typeof skillRef?.current !== "undefined")
            skillRef.current.scrollIntoView({block: "center", behavior: "smooth"});
        else
            window.open(`https://www.google.com/search?q=${tag}`, "blank");
    };
    let handleScroll = (event) => {
        /*   setScrollY(event.currentTarget.scrollTop);
           if (event.currentTarget.scrollTop < scrollY)
               setFlagHiddenFotter(true);
           else
               setFlagHiddenFotter(false);*/

    };
    let handleChangeNameFeedback = (e, isFooter = false) => {
        const notNumber = e.replace(/[^a-z | _ | а-я]/gi, "");
        if (isFooter)
            setNameFeedbackFooter(notNumber);
        else
            setNameFeedbackModal(notNumber);
    };
    let createApiQuestion = (obj) => {
        requests.quests.post_user_question(obj)
            .then((res) => {
                message.success(variable_29);
                setFlagSuccessSendQuestion(false);
            })
            .catch((err) => console.error(err));
    };
    let createUserQuestionFooter = () => {
        if (!flagSuccessSendQuestion) return message.error(variable_30);
        if (!nameFeedbackFooter) return message.error(variable_31);
        if (!phoneFooter) return message.error(variable_32);
        if (!textFeedbackFooter) return message.error(variable_33);

        let obj = {
            ses: data.ses,
            name: nameFeedbackFooter,
            phone: phoneFooter,
            text: textFeedbackFooter
        };
        createApiQuestion(obj);
    };
    let createUserQuestionModal = () => {
        if (!flagSuccessSendQuestion) return message.error(variable_30);
        if (!nameFeedbackModal) return message.error(variable_31);
        if (!phoneModal) return message.error(variable_32);
        if (!textFeedbackModal) return message.error(variable_33);

        let obj = {
            ses: data.ses,
            name: nameFeedbackModal,
            phone: phoneModal,
            text: textFeedbackModal
        };
        createApiQuestion(obj);
        setFlagModalFeedback(false);
    };
    let getApiCourse = () => {
        requests.quests.get_course_id("/" + quest_id)
            .then((res) => {
                setData(res.data);
                let addCompletedCourse = {
                    name: res.data.name,
                    description: res.data.description,
                    passed_date: new Date().getFullYear()
                }
                const newArr = JSON.parse(JSON.stringify(arrNavBar));

                setCompletedCourses(prev => [...prev, addCompletedCourse]);
                if (res.data?.quest_speakers && res.data.quest_speakers.length > 0)
                    newArr[1].valid = true;
                if (res.data?.quest_recommendations && res.data.quest_recommendations.length > 0)
                    newArr[3].valid = true;
                if (res.data?.quest_skills && res.data.quest_skills.length > 0)
                    newArr[4].valid = true;
                if (res.data?.speciality_tag?.length > 0) {
                    setSkillTags((prev) => {
                        let skills = [...skillTags];
                        res.data.speciality_tag.map(tag => {
                            skills = [...skills, tag.name]
                        })
                        setSkillTags(skills)
                    })
                }

                newArr[0].name = variable_1;
                newArr[1].name = variable_2;
                newArr[2].name = variable_3;
                newArr[3].name = variable_4;
                newArr[4].name = variable_5;
                setArrNavbar(newArr)
                setFlagShow404(false);
            })
            .catch((err) => {
                if (err.message.includes("Request failed with status code 404"))
                    setFlagShow404(true);
            });
    }
    let getApiMyCourse = () => {
        requests.quests.get_quest_quest([], currentPage, page_size, "", "", "finished", false, "")
            .then(res => setCompletedCourses(res.data.results))
            .catch((error) => console.error(error))
    }

    const skillRef = useRef();
    const speakersRef = useRef();
    const formatStudyRef = useRef();
    const whoSuitsRef = useRef();
    const speakersRefMobile = useRef();
    const formatStudyRefMobile = useRef();
    const whoSuitsRefMobile = useRef();
    const flagShowFormat = data !== null && Object.keys(data).length !== 0 &&
        (data.progress.count_video > 0 || data.progress.count_theories > 0 || data.progress.count_task > 0 || data.progress.count_code_task > 0 ||
            data.progress.count_task > 0 || data.progress.count_quiz > 0 || data.progress.count_article > 0);
    const skillList = [
        {
            id: 10,
            active: false,
            title: variable_3
        },
        {
            id: 4,
            active: true,
            title: variable_4
        },
        {
            id: 2,
            active: true,
            title: variable_5
        },
        {
            id: 5,
            active: true,
            title: variable_6

        },
        {
            id: 3,
            active: true,
            title: variable_7
        },
        {
            id: 7,
            active: true,
            title: variable_8
        },
        {
            id: 6,
            active: true,
            title: variable_9
        },
        {
            id: 1,
            active: true,
            title: variable_10
        }
    ];

    useEffect(() => {
        if (params?.token)
            dispatch(setIsLandingWeb(true))
    }, [])
    useEffect(() => {
        if (window.localStorage.getItem("token")) {
            getProfileUser()
            getApiMyCourse();

            (async () => {
                const userTags = (await requests.quests.get_user_tags()).data;
                skillList.forEach((skill) => {
                    if (userTags[`${skill.id - 1}`]) {
                        userTags[`${skill.id - 1}`].tags.map((t) => {
                            setSkillTags((prev) => [...prev, t.name])
                        })
                    }
                });
            })();
            setFlagAuth(true)
        }
    }, [window.localStorage.getItem("token")]);
    useEffect(() => {
        if (data !== null) {
            requests.quests.get_quest_review(data.id)
                .then((res) => {
                    setReviews(res.data);
                })
                .catch(err => console.error(err));
        }
    }, [data]);
    useEffect(() => {
        if (variable_1)
            getApiCourse();
    }, [variable_1])
    return (
        <div
            className="parent_landing web"
            onScroll={handleScroll}
        >
            <Header
                arrNavBar={arrNavBar}
                flagAuth={flagAuth}
                handleShowSkills={handleShowSkills}
                scrollIntoView={scrollIntoView}
                refs={{
                    speakersRef, formatStudyRef, whoSuitsRef,
                    speakersRefMobile, formatStudyRefMobile, whoSuitsRefMobile
                }}
                isLandingWeb={true}
            />
            {flagShow404 !== null &&
                <>
                    {flagShow404
                        ? <Error404/>
                        : <>
                            <Banner
                                data={data}
                                setFlagModalFeedback={setFlagModalFeedback}
                                flagAuth={flagAuth}
                                profile={profile}
                                quest_id={quest_id}
                                prop={props}
                                isLandingWeb={true}
                            />
                            {/*<PreviewSkills*/}
                            {/*    data={data}*/}
                            {/*    handleShowSkills={handleShowSkills}*/}
                            {/*/>*/}
                            {/*<Video*/}
                            {/*    data={data}*/}
                            {/*/>*/}
                            <Speakers
                                data={data}
                                speakersRef={speakersRef}
                                speakersRefMobile={speakersRefMobile}
                            />
                            <FormatStudy
                                data={data}
                                flagShowFormat={flagShowFormat}
                                formatStudyRef={formatStudyRef}
                                formatStudyRefMobile={formatStudyRefMobile}
                            />
                            <CourseCountains
                                data={data}
                            />
                            <WhoSuits
                                data={data}
                                whoSuitsRef={whoSuitsRef}
                                whoSuitsRefMobile={whoSuitsRefMobile}
                            />
                            <WhatLearn
                                data={data}
                                skillRef={skillRef}
                            />
                            <Resume
                                flagAuth={flagAuth}
                                completedCourses={completedCourses}
                                skillTags={skillTags}
                                isLandingWeb={true}
                            />
                            <FooterForm
                                data={data}
                                nameFeedbackFooter={nameFeedbackFooter}
                                phoneFooter={phoneFooter}
                                textFeedbackFooter={textFeedbackFooter}
                                handleChangeNameFeedback={handleChangeNameFeedback}
                                setPhoneFooter={setPhoneFooter}
                                setTextFeedbackFooter={setTextFeedbackFooter}
                                setFlagDropDownPhone={setFlagDropDownPhone}
                                flagDropDownPhone={flagDropDownPhone}
                                createUserQuestionFooter={createUserQuestionFooter}
                            />
                        </>
                    }
                </>
            }
            <>
                <Modal
                    open={flagModalFeedback}
                    footer={null}
                    className={"modal_feedback"}
                    width={"550px"}
                    closable={false}
                    centered={true}
                    onCancel={() => setFlagModalFeedback(false)}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    bodyStyle={{backgroundColor: "white", borderRadius: 40, padding: 40}}
                >
                    <div
                        className="close"
                        onClick={() => setFlagModalFeedback(false)}
                    >
                        <img src={close_icon} alt=""/>
                    </div>
                    <ReuseForm
                        nameFeedback={nameFeedbackModal}
                        phone={phoneModal}
                        textFeedback={textFeedbackModal}
                        setNameFeedback={(value => handleChangeNameFeedback(value, false))}
                        setPhone={(value) => setPhoneModal(value)}
                        setTextFeedback={(value) => setTextFeedbackModal(value)}
                        setFlagDropDownPhone={setFlagDropDownPhone}
                        flagDropDownPhone={flagDropDownPhone}
                        createUserQuestion={createUserQuestionModal}
                        isModal={true}
                    />
                </Modal>
            </>
        </div>
    );
};

export default LandingWeb;