import React, {useState} from 'react';
import checked from "../../../../images/landing/checked.svg";
import checked_mobile from "../../../../images/landing/checked_mobile.svg";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import Slider from "react-slick";
import {ReuseDots} from "../Landing";

const WhatLearn = ({data, skillRef}) => {
    const variable_19 = variableLanguage({keyPage: "landing", keyVariable: "variable_19"});

    const [activeSlideWhoSuits, setActiveSlideWhoSuits] = useState(0);
    const settingsWhoSuitsMobile = {
        /*dots: true,*/
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "who_suits-slides-mobile",
        arrows: false,
        initialSlide: activeSlideWhoSuits
    };

    return (
        <>
            {data && data?.quest_skills && data.quest_skills?.length > 0 && <>
                <div ref={skillRef} className="what_learn">
                    <div className="title"> {variable_19}</div>
                    <ul className={"list"}>
                        {data.quest_skills.map((elem, indx) =>
                            <li key={indx}>
                                <div className="parent_check">
                                    <div className="check_icon">
                                        <img src={checked}/>
                                    </div>
                                    {/*<div className="check_icon-mobile">*/}
                                    {/*    <img src={checked_mobile}/>*/}
                                    {/*</div>*/}
                                    <div className="skill">
                                        {elem.speciality_tag.name || ""}
                                    </div>
                                    <div className="info">
                                        {elem.description || ""}
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="who_suits-mobile">
                    <div className="title">
                        {variable_19}
                    </div>
                    <Slider
                        {...settingsWhoSuitsMobile}
                        afterChange={(e) => setActiveSlideWhoSuits(e)}
                        // ref={carouselRefWhoSuits}
                    >
                        {data.quest_skills.map((elem, indx) =>
                            <li key={indx}>
                                <div className="parent_check" style={{maxWidth: '300px', margin: '0 auto', color: "white", display: "flex", flexDirection: "column", gap: "8px"}}>
                                    <div className="check_icon">
                                        <img src={checked}/>
                                    </div>
                                    {/*<div className="check_icon-mobile">*/}
                                    {/*    <img src={checked_mobile}/>*/}
                                    {/*</div>*/}
                                    <div className="skill"  style={{color: "white", fontSize: "20px"}}>
                                        {elem.speciality_tag.name || ""}
                                    </div>
                                    <div className="info" style={{color: "#757A88", fontSize: "14px"}}>
                                        {elem.description || ""}
                                    </div>
                                </div>
                            </li>
                        )
                        }
                    </Slider>
                    <ReuseDots
                        activeSlide={activeSlideWhoSuits}
                        setActiveSlide={(indx) => setActiveSlideWhoSuits(indx)}
                        countDots={data.quest_skills.length}
                        // color={"#B1E5ED"}
                    />
                </div>
            </>
            }
        </>
    );
};

export default WhatLearn;