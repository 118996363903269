import React from 'react';

const PreviewSkills = ({data, handleShowSkills}) => {
    return (
        <>
            {data && Object.keys(data).length > 0 && data.speciality_tag.length > 0 &&
                <div className="preview_skills">
                    {data && data.speciality_tag.length > 0 &&
                        data.speciality_tag.map((skill, indx) =>
                            <div
                                // onClick={() => handleShowSkills(skill.name)}
                                key={indx}
                                className={"skill"}
                                style={{cursor: "pointer"}}
                            >
                                {skill.name}
                            </div>
                        )
                    }
                </div>
            }
        </>
    );
};

export default PreviewSkills;