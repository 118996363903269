import React from 'react';
import ReactPlayer from "react-player";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

const Video = ({data}) => {
    const variable_47 = variableLanguage({keyPage: "landing", keyVariable: "variable_47"});
    return (
        <>
            {(data !== null && data?.demo_video || data?.demo_youtube) ?
                <>
                    {/*<div className="title_video">{variable_47}</div>*/}
                    <div className="video">
                        <ReactPlayer
                            className="react-player"
                            style={{marginBottom: 15, width: "100%", borderRadius: 30}}
                            controls={true}
                            url={data?.demo_youtube ? data?.demo_youtube : data?.demo_video}
                        />
                    </div>
                </>
                : <></>
            }
        </>
    );
};

export default Video;