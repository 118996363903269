import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { ReuseDots } from "../Landing";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import format_study1 from "../../../../images/landing/format_study1.svg";
import format_study2 from "../../../../images/landing/format_study2.svg";
import format_study3 from "../../../../images/landing/format_study3.svg";
import format_study4 from "../../../../images/landing/format_study4.svg";
import format_study5 from "../../../../images/landing/format_study5.svg";
import format_study6 from "../../../../images/landing/format_study6.svg";

const WhoSuits = ({data, whoSuitsRef, whoSuitsRefMobile}) => {
    const variable_18 = variableLanguage({keyPage: "landing", keyVariable: "variable_18"});

    const [activeSlideWhoSuits, setActiveSlideWhoSuits] = useState(0);
    const settingsWhoSuitsMobile = {
        /*dots: true,*/
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "who_suits-slides-mobile",
        arrows: false,
        initialSlide: activeSlideWhoSuits
    };
    const carouselRefWhoSuits = useRef(null);

    const getImg = (i) => {
        if (i === 1) return format_study2
        if (i === 2) return format_study3
        if (i === 3) return format_study4
        if (i === 4) return format_study5
        if (i === 5) return format_study6
        return format_study1
    }

    useEffect(() => {
        if (carouselRefWhoSuits.current?.slickGoTo)
            carouselRefWhoSuits.current.slickGoTo(activeSlideWhoSuits);
    }, [activeSlideWhoSuits]);
    return (
        <>
            {data && data?.quest_recommendations && data.quest_recommendations?.length > 0 &&
                <>
                    <div ref={whoSuitsRef} className="who_suits format_study">
                        <div className="title">
                            {variable_18}
                        </div>
                        <ul className="list">
                            {data && data.quest_recommendations && data.quest_recommendations.length > 0 &&
                                data.quest_recommendations.map((elem, indx) =>
                                    // <li key={indx}>
                                    //     <div className="number"><span>{indx + 1}</span></div>
                                    //     <div className="to_whom">{elem.title || ""}</div>
                                    //     <div className="info">{elem.description || ""}</div>
                                    // </li>
                                    <li>
                                        <div className="bottom">
                                            <img src={getImg(indx)}/>
                                        </div>
                                        <div className="top">
                                            <div className="title" style={{width: 'unset'}}>{elem?.title}</div>
                                            <div className="info">{elem?.description}</div>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="who_suits-mobile" ref={whoSuitsRefMobile}>
                        <div className="title">
                            {variable_18}
                        </div>
                        <Slider
                            {...settingsWhoSuitsMobile}
                            afterChange={(e) => setActiveSlideWhoSuits(e)}
                            ref={carouselRefWhoSuits}
                        >
                            {data.quest_recommendations.map((elem, indx) =>
                                // <div key={indx}>
                                //     <div className={"slider_item"}>
                                //         <div className="number"><span>{indx + 1}</span></div>
                                //         <div className="to_whom">{elem.title || ""}</div>
                                //         <div className="info">{elem.description || ""}</div>
                                //     </div>
                                // </div>
                                <div className={"parent_slider-item"}>
                                    <div className="slider_item">
                                        <div className="bottom">
                                            <img src={getImg(indx)}/>
                                        </div>
                                        <div className="top">
                                            <div className="title" style={{width: 'unset'}}>{elem?.title}</div>
                                            <div className="info">{elem?.description}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                            }
                        </Slider>
                        <ReuseDots
                            activeSlide={activeSlideWhoSuits}
                            setActiveSlide={(indx) => setActiveSlideWhoSuits(indx)}
                            countDots={data.quest_recommendations.length}
                            // color={"#B1E5ED"}
                        />
                    </div>
                </>
            }
        </>
    );
};

export default WhoSuits;