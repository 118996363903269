import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

import burger_close from "../../../../images/landing/close_burger.svg";
import burger from "../../../../images/landing/burger.svg";
import { ReactComponent as Logo } from "../../../../images/icons/logo.svg";
import Input from "../../../components/Input/Input";
import settings_mobile from "../../../../images/icons/settings_mobile.svg";
import settings_mobile_category from "../../../../images/icons/settings_mobile-category.svg";
import close_modal_grey from "../../../../images/icons/close_modal-course.svg";
import {Modal} from "antd";
import {useDispatch} from "react-redux";
import {changeLanguage} from "../../../redux/actions/authorizationActions";

const Header = ({arrNavBar, handleShowSkills, refs, flagAuth, scrollIntoView, isLandingWeb = false}) => {
        const history = useNavigate();

        const variable_2 = variableLanguage({keyPage: "landing", keyVariable: "variable_2"});
        const variable_3 = variableLanguage({keyPage: "landing", keyVariable: "variable_3"});
        const variable_4 = variableLanguage({keyPage: "landing", keyVariable: "variable_4"});
        const variable_5 = variableLanguage({keyPage: "landing", keyVariable: "variable_5"});
        const variable_6 = variableLanguage({keyPage: "landing", keyVariable: "variable_6"});

        const dispatch = useDispatch();
        const [flagModalSettingsMobile, setFlagModalSettingsMobile] = useState(false);
        const [flagBurgerMobile, setFlagBurgerMobile] = useState(false);

        const [lang, setLang] = useState({selected: "ru", list: ["ru","en"], active: false});
        const getLangFlag = (name, cb) => {
            if (name === 'ru') return <svg onClick={cb} width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0" y="0" width="25" height="6" fill="white"/>
                <rect x="0" y="6" width="25" height="6" fill="#0F56E0"/>
                a <rect x="0" y="12" width="25" height="6" fill="#D22222"/>
            </svg>
            if (name === 'en') return <svg onClick={cb} width="25" height="18" viewBox="0 0 25 18" xmlns="http://www.w3.org/2000/svg">
                <rect width="25" height="18" fill="#00247D"/>
                <polygon points="0,0 25,18 22,18 0,2" fill="white"/>
                <polygon points="25,0 0,18 3,18 25,2" fill="white"/>
                <polygon points="0,0 25,18 21,18 0,3" fill="#CF142B"/>
                <polygon points="25,0 0,18 4,18 25,3" fill="#CF142B"/>
                <rect x="10" width="5" height="18" fill="white"/>
                <rect y="7" width="25" height="4" fill="white"/>
                <rect x="11" width="3" height="18" fill="#CF142B"/>
                <rect y="8" width="25" height="2" fill="#CF142B"/>
            </svg>
        }
        return (
            <>
                <header>
                    <div className="title" onClick={() => window.open("https://skillometer.ru", "blank")}>
                        Курсы
                    </div>
                    <div className="nav_bar">
                        <ul>
                            {arrNavBar.map((elem, indx) => {
                                    if (elem.valid) {
                                        return (
                                            <li
                                                key={indx}
                                                onClick={() => {
                                                    if (indx === 0) {
                                                        if (flagAuth)
                                                            history("/student/course-list");
                                                        else
                                                            history("/course-list");
                                                    }
                                                    if (elem.name === variable_2) scrollIntoView(refs.speakersRef);
                                                    if (elem.name === variable_3) scrollIntoView(refs.formatStudyRef);
                                                    if (elem.name === variable_4) scrollIntoView(refs.whoSuitsRef);
                                                    if (elem.name === variable_5) handleShowSkills(elem.name);
                                                }}
                                            >
                                                {elem.name}
                                            </li>
                                        );
                                    }
                                }
                            )}
                        </ul>
                    </div>
                    <div className={lang.active ? 'lang_changer open' : 'lang_changer'}>
                        {lang.active
                            ? <>{lang.list.map((el, i) => <div key={i} onClick={() => {
                                setLang((prev) => ({...prev, active: false, selected: el}))
                                dispatch(changeLanguage(el))
                            }}>{el.toUpperCase()}</div>)}</>
                            : <>
                                {getLangFlag(lang.selected, () => setLang((prev) => ({...prev, active: true})))}
                                <div onClick={() => setLang((prev) => ({...prev, active: true}))}>{lang.selected.toUpperCase()}</div>
                            </>
                        }
                    </div>
                    {(!isLandingWeb && !flagAuth) &&
                        <div
                            className="btn_redirect"
                            onClick={() => history("/student/auth")}
                        >
                            {variable_6}
                        </div>
                    }
                </header>
                <div className="top_block-mobile">
                    <div className={"top_filter"}>
                        <div className="icon">
                            <Logo
                                width={38}
                                height={38}
                                onClick={() => window.open("https://skillometer.ru/", 'blank')}
                            />
                        </div>
                        <div
                            className="settings"
                            onClick={() => {
                                setFlagModalSettingsMobile(true);
                            }}
                        >
                            <div style={{width: '30px', height: '30px', borderRadius: '12px', backgroundColor: '#4B4F5E', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#A4A9C9">
                                    <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/>
                                </svg>
                            </div>
                            {/*<img src={settings_mobile}/>*/}
                        </div>
                    </div>
                </div>
                <Modal
                    open={flagModalSettingsMobile}
                    footer={null}
                    className={"modal_settings-mobile parent_landing"}
                    // width={"auto"}
                    closable={false}
                    centered={true}
                    onCancel={() => {
                        setFlagModalSettingsMobile(false);
                    }}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    bodyStyle={{padding: "15px", backgroundColor: "#343843", borderRadius: 16}}
                >
                    <div className={"drop_down-header active"}>
                        <ul style={{minHeight: 'unset'}} className={"list"}>
                            {arrNavBar.map((elem, indx) => {
                                    if (elem.valid)
                                        return (
                                            <li
                                                key={indx}
                                                onClick={() => {
                                                    if (indx === 0) {
                                                        if (flagAuth)
                                                            history("/student/course-list");
                                                        else
                                                            history("/course-list");
                                                    }


                                                    if (elem.name === variable_2) scrollIntoView(window.innerWidth > 820 ? refs.speakersRef : refs.speakersRefMobile);
                                                    if (elem.name === variable_3) scrollIntoView(window.innerWidth > 820 ? refs.formatStudyRef : refs.formatStudyRefMobile);
                                                    if (elem.name === variable_4) scrollIntoView(window.innerWidth > 820 ? refs.whoSuitsRef : refs.whoSuitsRefMobile);
                                                    if (elem.name === variable_5) handleShowSkills(elem.name);
                                                }}
                                            >
                                                {elem.name}
                                            </li>
                                        );
                                }
                            )}
                        </ul>
                        {!isLandingWeb && <div style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center'}}>
                            <div className={lang.active ? 'lang_changer open' : 'lang_changer'}>
                                {lang.active
                                    ? <>{lang.list.map((el, i) => <div key={i} onClick={() => {
                                        setLang((prev) => ({...prev, active: false, selected: el}))
                                        dispatch(changeLanguage(el))
                                    }}>{el.toUpperCase()}</div>)}</>
                                    : <>
                                        {getLangFlag(lang.selected, () => setLang((prev) => ({...prev, active: true})))}
                                        <div onClick={() => setLang((prev) => ({...prev, active: true}))}>{lang.selected.toUpperCase()}</div>
                                    </>
                                }
                            </div>
                            <div style={{margin: 0}} className="btn_redirect-burger" onClick={() => history("/student/auth")}>{variable_6}</div>
                        </div>
                        }
                    </div>
                </Modal>
                <div
                    className={flagBurgerMobile
                        ? "drop_down-header active"
                        : "drop_down-header"}
                >
                    <ul className={"list"}>
                        {arrNavBar.map((elem, indx) => {
                                if (elem.valid)
                                    return (
                                        <li
                                            key={indx}
                                            onClick={() => {
                                                if (indx === 0) {
                                                    if (flagAuth)
                                                        history("/student/course-list");
                                                    else
                                                        history("/course-list");
                                                }


                                                if (elem.name === variable_2) scrollIntoView(window.innerWidth > 820 ? refs.speakersRef : refs.speakersRefMobile);
                                                if (elem.name === variable_3) scrollIntoView(window.innerWidth > 820 ? refs.formatStudyRef : refs.formatStudyRefMobile);
                                                if (elem.name === variable_4) scrollIntoView(window.innerWidth > 820 ? refs.whoSuitsRef : refs.whoSuitsRefMobile);
                                                if (elem.name === variable_5) handleShowSkills(elem.name);
                                            }}
                                        >
                                            {elem.name}
                                        </li>
                                    );
                            }
                        )}
                    </ul>
                    {!isLandingWeb &&
                        <div
                            className="btn_redirect-burger"
                            onClick={() => history("/student/auth")}
                        >
                            {variable_6}
                        </div>
                    }
                </div>
            </>
        );
    }
;

export default Header;